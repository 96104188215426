
import {
  Flex,
  Table,
  Tbody,
  TableContainer,
  Td,
  Input,
  Th,
  Thead,
  Button,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  FormControl,
  FormLabel,
  Tr,
  Box,
  Spinner,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";

import { Link } from 'react-router-dom';
import { fetchGetDataApi } from "helper/api";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Bars } from "react-loader-spinner";
import PaginatedLinks from "helper/PaginatedLinks";

import { postDataToApi } from "helper/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import { SearchBar } from "components/navbar/searchBar/SearchBar";

export default function HistoryUrl(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const [historyData, setHistoryData] = useState("")
  const [searchValue, setSearchValue] = useState("");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [category, setCategory] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    console.log("uploaded file : ", file);
  };

  useEffect(() => {
    handleSearchList();
    document.title = "History";
  }, [searchValue]);

  const openAddModal = () => {
    setCategory("");
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };



  const handleSearchList = async (page = 1) => {
    try {
      searchValue.length ? setLoader(true) : setLoading(true);
      const response = await fetchGetDataApi(
        `api/history/list-search?pageNo=${page}&search=${searchValue}`
      );
      if (response.status === 200) {
        setHistoryData(response.data.result);
        setCurrentPage(response.data.result.pagination.currentPage);
        setLastPage(
          Math.ceil(
            response.data.result.pagination.total /
            response.data.result.pagination.pageSize
          )
        );

      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage);
      } else {
        toast.error("SomeThing Went Wrong!");
      }
    } finally {
      setLoader(false);
      setLoading(false);
    }
  };

  const handleAddDocument = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();
    formData.append("file", selectedFile);
    formData.append("categories", category);
    try {
      setIsAddModalOpen(false);
      setLoading(false);

      const response = await postDataToApi("api/file/search", formData);
      e.preventDefault();

      handleSearchList();
      e.preventDefault();

      console.log({ response });
      if (response.status === 200) {
        let totalCount = response.data.result;
        toast.success(`Please wait, while we are getting the response total responses are: ${totalCount}`, {
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          draggable: true,
          progress: undefined,
        });
        console.log({ totalCount });
        let count = 0;
        let interval = setInterval(() => {
          if (count >= totalCount) {
            clearInterval(interval);
            toast.success("Document Added Successfully");
            handleSearchList();
          }
          else {
            count = count + 10;
            if (count >= totalCount) {
              count = totalCount
            }
            toast.success(`Please wait, while we are getting the response.. ${count} / ${totalCount}`, {
              autoClose: false,
              hideProgressBar: false,
              closeOnClick: true,
              draggable: true,
              progress: undefined,
            });
          }
        }, 2000);
      } else {
        toast.error("Error Submitting Document");
      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage);
      } else {
        toast.error("Request Failed!");
      }

    } finally {
      setLoading(false);
    }
  };

  const [loader, setLoader] = useState(false);


  const onPageChange = (page) => {

    if (page < 1) {
      page = 1;
    } else if (page > lastPage) {
      page = lastPage;
    }

    handleSearchList(page);
  };
  const handleDelete = async (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action is irreversible",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        postDataToApi("api/history/delete", {
          id: _id,
        })
          .then((response) => {
            if (response.status === 200) {
              handleSearchList();
              toast.success("History Removed From List");
            } else {
              toast.error("Failed to Delete history!");
            }
          })
          .catch((error) => {

          });
        console.log("Deleting...");
      }
    });
  };



  function formatDateOnly(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  }

  return (
    <>

      <Bars
        height="80"
        width="80"
        color="#1b3bbb"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="loading-spinner-overlay"
        visible={loading}
      />

      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex
            px="25px"
            justify="space-between"
            mb="20px"
            align="center"
            style={{ float: "right" }}
          >
            <Flex align="center">
              <SearchBar
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
              {loader && <Spinner color="#1b3bbb" ml="2" />}{" "}
            </Flex>

            <Button size="sm" onClick={openAddModal} colorScheme="green">
              Add
            </Button>

          </Flex>


          <TableContainer>
            <Table mt="20px" variant="simple">
              <Thead>
                <Tr>
                  <Th>Category</Th>

                  <Th>Website</Th>

                  <Th>Created At</Th>


                  <Th>Actions</Th>
                </Tr>
              </Thead>


              <Tbody>
                {historyData?.length === 0 ? (
                  <Tr>
                    <Td>No Data Available</Td>
                  </Tr>
                ) : (
                  historyData?.data?.map((data) => (
                    <>

                      <Tr>
                        <Td>{data?.category}</Td>
                        <Td>

                          {Array.isArray(data.website) ? (

                            <div dangerouslySetInnerHTML={{ __html: data.website.join("<br/>") }}></div>

                          ) :
                            (
                              <div dangerouslySetInnerHTML={{ __html: data.website }}></div>
                            )}
                        </Td>
                        <Td>{formatDateOnly(data?.createdAt)}</Td>

                        <Td>
                          <HStack spacing={2}>

                            <>
                              <Button
                                size="sm"
                                colorScheme="red"
                                onClick={() => handleDelete(data?._id)}
                              >
                                Delete
                              </Button>

                              <Link to={{
                                pathname: `/records`,
                                state: { recordId: data._id }
                              }}>
                                <Button size="sm" onClick={openAddModal} colorScheme="green">
                                  Details
                                </Button>
                              </Link>

                            </>

                          </HStack>
                        </Td>
                      </Tr>
                    </>

                  ))

                )}
              </Tbody>
            </Table>
          </TableContainer>
          <PaginatedLinks
            data={historyData}
            currentPage={currentPage}
            lastPage={lastPage}
            onPageChange={onPageChange}
          />

          <Modal isOpen={isAddModalOpen} onClose={closeAddModal} size="md">
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Add Document</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <form onSubmit={handleAddDocument}>
                  <VStack spacing={4}>

                    <FormControl>
                      <FormLabel className="required">Category</FormLabel>
                      <Input
                        name="category"
                        placeholder="Select Category"
                        value={category}
                        required
                        onChange={(e) => setCategory(e.target.value)}
                        type="text"
                      />
                      {/* {showDocumentsAll?.data?.map((user) => (
                            <option key={user?._id} value={user?._id}>
                              {user?.name}
                            </option> 
                          ))} */}

                    </FormControl>

                    <FormControl>
                      <FormLabel className="required">File</FormLabel>
                      <Input
                        type="file"
                        name="file"
                        onChange={handleFileUpload}
                        required
                      />
                    </FormControl>
                  </VStack>
                  <Button
                    type="submit"
                    colorScheme="blue"
                    mt={4}
                    style={{ float: "right" }}
                  >
                    Save
                  </Button>
                </form>
              </ModalBody>
            </ModalContent>
          </Modal>
        </Card>
      </Box>
    </>
  );
}
