// chakra imports
import { Box, Flex, Stack } from "@chakra-ui/react";
//   Custom components
import Brand from "components/sidebar/components/Brand";
import Links from "components/sidebar/components/Links";
import React from "react";

// FUNCTIONS

function SidebarContent(props) {
  const { routes } = props;
  const userType = localStorage.getItem("userType");

  function filterRoutesByUserType(routes) {
    const userType = localStorage.getItem("userType");
    if (userType === "Admin" || userType === "HR") {
      return routes.filter((route) => route.path !== "/companies");
    } else if (userType === "Employee") {
      // Customize this logic as per your requirement
      // For example, to show "Companies," remove the path condition
      return routes.filter((route) => route.path === "/employees");
    } else {
      return routes;
    }
  }

  console.log("My Routes : ", routes);

  // SIDEBAR
  return (
    <Flex
      direction="column"
      height="100%"
      pt="25px"
      px="16px"
      borderRadius="30px"
    >
      <Brand />
      <Stack direction="column" mb="auto" mt="8px">
        <Box ps="20px" pe={{ md: "16px", "2xl": "1px" }}>
          <Links routes={routes} />
        </Box>
      </Stack>
    </Flex>
  );
}

export default SidebarContent;
