// Chakra Imports
import {
  Avatar,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Button,
  useColorModeValue,
  Link as ChakraLink,
  Wrap,
  Badge,
  Box,
} from "@chakra-ui/react";
// Custom Components

import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import routes from "routes.js";
import { showLoader } from "helper/general";
import { hideLoader } from "helper/general";
// import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Bars } from "react-loader-spinner";

import { fetchGetDataApi } from "helper/api";

import { postDataToApi } from "helper/api";
import { URL } from "helper/general";

export default function HeaderLinks(props) {
  const { secondary } = props;

  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");

  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  const borderButton = useColorModeValue("secondaryGray.500", "whiteAlpha.200");
  const history = useHistory();
  const [notifications, setNotifications] = useState([]);
  const userId = localStorage.getItem("userId");
  const [unread, setUnread] = useState("");

  const [loading, setloading] = useState(false);
  const [text, setText] = useState("Mark as Read");
  const userName = localStorage.getItem("userName");
  const role = localStorage.getItem("userType");
  const [selectedImageDataUrl, setSelectedImageDataUrl] = useState(null);



  const handleLogout = async (e) => {
    setloading(true);
    localStorage.removeItem("userToken");
    localStorage.removeItem("userName");
    localStorage.removeItem("userEmail");
    localStorage.removeItem("userId");
    localStorage.removeItem("userType");
    // window.location.replace("/auth");
    toast.success("Logout Successfull!");
    history.push("/auth");
    window.location.reload();
    setloading(false);
  };


  
  return (
    <>
      <Bars
        height="80"
        width="80"
        color="#1b3bbb"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="loading-spinner-overlay"
        visible={loading}
      />

      <Flex
        w={{ sm: "100%", md: "auto" }}
        alignItems="center"
        flexDirection="row"
        bg={menuBg}
        flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
        p="10px"
        borderRadius="30px"
        boxShadow={shadow}
      >
        <SidebarResponsive routes={routes} />
          <Menu>
          <MenuButton p="0px">
            <Avatar
              _hover={{ cursor: "pointer" }}
              color="white"
              // name={userName}
              src={selectedImageDataUrl}
              // bg="#11047A"
              // size="sm"
              // w="40px"
              // h="40px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <Flex w="100%" mb="0px">
              <Text
                ps="20px"
                pt="16px"
                pb="10px"
                w="100%"
                borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
              >
                👋&nbsp; Hey, {userName}
              </Text>
            </Flex>
            <Flex flexDirection="column" p="10px">
              {/* <MenuItem
                
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                borderRadius="8px"
                px="14px"
              >
                <ChakraLink as={Link} to="/profile">
                <Text fontSize="sm">Profile Settings</Text>
                </ChakraLink>
              </MenuItem> */}

              {/* <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                borderRadius="8px"
                px="14px"
              >
                <Text fontSize="sm">Newsletter Settings</Text>
              </MenuItem> */}
              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                onClick={handleLogout}
                color="red.400"
                borderRadius="8px"
                px="14px"
              >
                <Text fontSize="sm">Log out</Text>
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>
      </Flex>
    </>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
