import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import RtlLayout from "layouts/rtl";
import { ChakraProvider, useAccordion } from "@chakra-ui/react";
import theme from "theme/theme";
import { ThemeEditorProvider } from "@hypertheme-editor/chakra-ui";
import ForgetPassword from "views/auth/forgetPassword";
import ResetPassword from "views/auth/resetPassword";

import Loader from "components/loader";
import { ToastContainer } from "react-toastify";
import FirstPasswordChange from "views/auth/FIrstChangePassword";
import Records from "views/admin/records";

const token = localStorage.getItem("userToken");

ReactDOM.render(
  <ChakraProvider theme={theme}>
    <React.StrictMode>
      <ThemeEditorProvider>
        <Loader />
        <HashRouter>
          <Switch>
            <Route path={"/forget-password"} component={ForgetPassword} />
            {/* <Route path={"/profile"} component={UserProfile} /> */}
            
            <Route path={"/change-password"} component={FirstPasswordChange} />
            <Route path={"/records"} layout="/admin" component={Records} />
            <Route
              path={"/reset-password/:token/:email"}
              component={ResetPassword}
            />
            <Route path={`/auth`} component={AuthLayout} />
            <Route path={`/admin`} component={AdminLayout} />
            <Route path={`/rtl`} component={RtlLayout} />
            {token ?
             (<Redirect from="/" to="/admin" />):(<Redirect from="/" to="/auth" />)}
          </Switch>
          <ToastContainer />
        </HashRouter>
      </ThemeEditorProvider>
    </React.StrictMode>
  </ChakraProvider>,
  document.getElementById("root")
);
