export const showLoader = () => {
  let loader = document.querySelector(".loader");
  if (loader) {
    loader.classList.remove("show");
    loader.classList.add("show");
  }
};

export const hideLoader = () => {
  let loader = document.querySelector(".loader");
  if (loader) {
    loader.classList.remove("show");
  }
};

export const URL = "https://hrm-backend.qwertyexperts.com/";

// export const URL = " http://localhost:4000/";
