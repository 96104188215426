
import {
  Flex,
  Table,
  Tbody,
  TableContainer,
  Td,
  Input,
  Th,
  Thead,
  Button,
  Tr,
  Box,
  Spinner,
} from "@chakra-ui/react";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import routes from "routes.js";
import { NavLink } from "react-router-dom";
import Card from "components/card/Card";
import { useParams } from 'react-router-dom';
import { fetchGetDataApi } from "helper/api";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Bars } from "react-loader-spinner";
import PaginatedLinks from "helper/PaginatedLinks";
import { SearchBar } from "components/navbar/searchBar/SearchBar";

import { useLocation } from 'react-router-dom';


export default function Records(props) {
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [historyData, setHistoryData] = useState("")
  const [searchValue, setSearchValue] = useState("");
  const location = useLocation();
  const recordId = location.state?.recordId;


  useEffect(() => {
    handleHistoryList();
    document.title = "Details";
  }, [searchValue]);


  const handleHistoryList = async (page = 1) => {
    try {
      searchValue.length ? setLoader(true) : setLoading(true);
      const response = await fetchGetDataApi(
        `api/history/list?&pageNo=${page}&searchId=${recordId}&search=${searchValue}`
      );
      if (response.status === 200) {
        setHistoryData(response.data.result);
        setCurrentPage(response.data.result.pagination.currentPage);
        setLastPage(
          Math.ceil(
            response.data.result.pagination.total /
            response.data.result.pagination.pageSize
          )
        );

      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage);
      } else {
        toast.error("SomeThing Went Wrong!");
      }
    } finally {
      setLoader(false);
      setLoading(false);
    }
  };


  const [loader, setLoader] = useState(false);


  const onPageChange = (page) => {

    if (page < 1) {
      page = 1;
    } else if (page > lastPage) {
      page = lastPage;
    }

    handleHistoryList(page);
  };

  function formatDateOnly(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  }



  return (
    <>
      <Bars
        height="80"
        width="80"
        color="#1b3bbb"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="loading-spinner-overlay"
        visible={loading}
      />
      <SidebarResponsive routes={routes} />

      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex
            px="25px"
            justify="space-between"
            mb="20px"
            align="center"
            style={{ float: "right" }}
          >
            <Flex align="center">
              <SearchBar
                searchValue={searchValue}
                setSearchValue={setSearchValue}
              />
              {loader && <Spinner color="#1b3bbb" ml="2" />}{" "}
            </Flex>
            <NavLink to="admin/history">
              <Button size="sm" colorScheme="red">
                Back
              </Button>
            </NavLink>



          </Flex>
          <TableContainer>
            <Table mt="20px" variant="simple">
              <Thead>
                <Tr>
                  <Th>Category</Th>

                  <Th>Website</Th>

                  <Th>Created At</Th>
                  <Th>Links</Th>
                </Tr>
              </Thead>
              <Tbody>
                {historyData?.length === 0 ? (
                  <Tr>
                    <Td>No Data Available</Td>
                  </Tr>
                ) : (
                  historyData?.data?.map((data) => (
                    <>

                      <Tr>
                        <Td>{data?.category}</Td>

                        <Td>{data?.website}</Td>
                        <Td>{formatDateOnly(data?.createdAt)}</Td>

                        <Td>
                          <div className="hover-link" dangerouslySetInnerHTML={{
                            __html: data.links.map((url) => `<a style="color: blue" href="${url}" target="_blank" rel="noopener noreferrer" ;>${url}</a>`).join("<br>")
                          }}>
                          </div>
                        </Td>
                      </Tr >
                    </>

                  ))

                )}
              </Tbody>
            </Table>
          </TableContainer>
          <PaginatedLinks
            data={historyData}
            currentPage={currentPage}
            lastPage={lastPage}
            onPageChange={onPageChange}
          />


        </Card>
      </Box >
    </>
  );
}
