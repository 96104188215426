import axios from "axios";

// axios.defaults.baseURL = "http://localhost:4000";
axios.defaults.baseURL = "https://rankviz-tool-backend.qwertyexperts.com";

export const fetchGetDataApi = async (path) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("userToken")}`;
  try {
    const response = await axios.get(path);
    return response;
  } catch (error) {
    console.error("Error fetching data from API: ", error);
    throw error; // Optionally re-throw the error to handle it in the component.
  }
};

export const postDataToApi = async (path, data = {}) => {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.getItem("userToken")}`;

  try {
    const response = await axios.post(path, data);
    return response;
  } catch (error) {
    console.error("Error posting data to API: ", error);
    throw error; // Optionally re-throw the error to handle it in the component.
  }
};
