import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  
  MdLock,
 
  MdPeople,
  MdSearch,
 
} from "react-icons/md";
import { Redirect } from "react-router-dom";
import SignInCentered from "views/auth/signIn";
import EmployeeTable from "views/adminEmployees";
import HistoryUrl from "views/admin/history";
import FirstPasswordChange from "views/auth/FIrstChangePassword";

const role = localStorage.getItem("userType");
const token = localStorage.getItem("userToken");
let routes = [];

if (token === "") {
  routes = [
    {
      name: "Sign In",
      layout: "/auth",
      path: "/sign-in",
      icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      render: true,
      component: SignInCentered,
    },
  ];
} else if (!token) {
  routes = [
    {
      name: "Sign In",
      layout: "/auth",
      path: "/sign-in",
      icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      render: true,
      component: SignInCentered,
    },
  ];
} else if(role==="User") {
  routes = [
   
   
    {
      name: "Search History",
      layout: "/admin",
      icon: <Icon as={MdSearch} width="20px" height="20px" color="inherit" />,
      render: true,
      component: HistoryUrl,
      path: "/history",
    },
    {
      name: "Password Change",
      layout: "/admin",
      icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      render: true,
      component: FirstPasswordChange,
      path: "/password-change",
    },
  ]

}
else if (role==="Admin") {
  routes = [
    <Redirect from="/auth" to="/admin" />,
    {
      name: "Users",
      layout: "/admin",
      icon: <Icon as={MdPeople} width="20px" height="20px" color="inherit" />,
      render: true,
      component: EmployeeTable,
      path: "/users",
    },
    {
      name: "Search History",
      layout: "/admin",
      icon: <Icon as={MdSearch} width="20px" height="20px" color="inherit" />,
      render: true,
      component: HistoryUrl,
      path: "/history",
    },
    {
      name: "Password Change",
      layout: "/admin",
      icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
      render: true,
      component: FirstPasswordChange,
      path: "/password-change",
    },
]};


 
export default routes;
