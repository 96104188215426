import React from "react";
import { ChevronLeft, ChevronRight } from "react-feather"; // Import Feather Icons

const PaginatedLinks = ({ currentPage, lastPage, onPageChange }) => {
  const range = 2;
  const startPage = Math.max(currentPage - range, 1);
  const endPage = Math.min(currentPage + range, lastPage);

  const links = [];
  for (let i = startPage; i <= endPage; i++) {
    const isActive = i === currentPage;
    links.push(
      <li className={`page-item ${isActive ? "active" : ""}`} key={i}>
        <a
          className="page-link"
          href="#"
          onClick={(e) => {
            e.preventDefault();
            onPageChange(i);
          }}
        >
          {i}
        </a>
      </li>
    );
  }

  return (
    <nav>
      <ul
        className="pagination mx-4"
        style={{ float: "right", marginRight: "10px" }}
      >
        <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
          <a
            className="page-link"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onPageChange(currentPage - 1);
            }}
            aria-label="Previous"
          >
            <ChevronLeft size={20} />
          </a>
        </li>
        {links}
        <li
          className={`page-item ${currentPage === lastPage ? "disabled" : ""}`}
        >
          <a
            className="page-link"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onPageChange(currentPage + 1);
            }}
            aria-label="Next"
          >
            <ChevronRight size={20} />
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default PaginatedLinks;
