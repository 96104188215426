/* eslint-disable */
import {
  Flex,
  Table,
  Tbody,
  TableContainer,
  Td,
  Input,
  Th,
  Thead,
  Button,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  FormControl,
  FormLabel,
  Tr,
  Box,
  Spinner,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import { fetchGetDataApi } from "helper/api";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Bars } from "react-loader-spinner";
import PaginatedLinks from "helper/PaginatedLinks";
import { postDataToApi } from "helper/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Swal from "sweetalert2";
import { SearchBar } from "components/navbar/searchBar/SearchBar";

export default function EmployeeTable(props) {
 
  const history = useHistory("");
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [usersData, setusersData] = useState({});  
  const [searchValue, setSearchValue] = useState("");
  const role = localStorage.getItem("userType");
 
  const [formData, setFormdata] = useState({
    email: "",
    name: "",
  });

  const [editformData, setEditFormdata] = useState({});
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    handleUsers();
    document.title = "Users";
  }, [searchValue]);

  const handleUsers = async (page = 1) => {
    try {
      searchValue.length ? setLoader(true) : setLoading(true);
      const response = await fetchGetDataApi(
        `api/user/list?pageNo=${page}&search=${searchValue}`
      );
      if (response.status === 200) {
        setusersData(response.data.result);
        setCurrentPage(response.data.result.pagination.currentPage);
        setLastPage(
          Math.ceil(
            response.data.result.pagination.total /
              response.data.result.pagination.pageSize
          )
        );
        
      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage);
      } else {
        toast.error("SomeThing Went Wrong!");
      }
    } finally {
      setLoader(false);
      setLoading(false);
    }
  };



  const handleAddUser = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await postDataToApi("api/user/add", formData);

      if (response.status === 200) {
        toast.success("User Added Successfully");
        handleUsers();
        
       
        setIsAddModalOpen(false);
      } else {
        toast.error("Could Not Add User!");
      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage);
      } else {
        toast.error("Failed To Login!");
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSetAddFields = (event) => {
    const { name, value } = event.target;

    setFormdata((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSetEditFields = (event) => {
    const { name, value } = event.target;

    setEditFormdata((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

 

  const handleShowUser = async (id) => {
    try {
      const response = await fetchGetDataApi(`api/user/show/${id}`);
      
      setEditFormdata({
        ...response.data?.result,
        name: response.data?.result?.name,
        email: response.data?.result?.email,
        
      });
      
      
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage);
      } else {
        toast.error("Failed To Login!");
      }
      console.error("Error Fetching Response: ", error);
    }
  };
  const [userid, setUserId] = useState("");

  const onPageChange = (page) => {
    // Make sure to handle boundary conditions for page navigation
    if (page < 1) {
      page = 1;
    } else if (page > lastPage) {
      page = lastPage;
    }

    handleUsers(page);
  };

  const openEditModal = async (id) => {
    setUserId(id);
    await handleShowUser(id);

    setIsEditModalOpen(true);
    console.log(editformData);
  };

  const closeEditModal = () => {
    setIsEditModalOpen(false);
  };

  

  const handleEditSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await postDataToApi("api/user/update", {userId : userid,name:editformData.name});

      if (response.status === 200) {
        toast.success("User Updated Successfully");
        handleUsers();
        closeEditModal();
      } else {
        toast.error("Could Not Update User!");
      }
    } catch (error) {
      if (error.response) {
        const errorMessage = error.response.data.message;
        toast.error(errorMessage);
      } else {
        toast.error("Failed To Login!");
      }
      console.error("Error Fetching Response: ", error);
    } finally {
      setLoading(false);
    }
    
  };

  const openAddModal = () => {
    setFormdata({
      email: "",
      name: "",
    
    });
    setIsAddModalOpen(true);
  };

  const closeAddModal = () => {
    setIsAddModalOpen(false);
  };

  const handleDelete = async (_id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This action is irreversible",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        postDataToApi("api/user/delete", {
          id: _id,
        })
          .then((response) => {
            if (response.status === 200) {
              handleUsers();
              toast.success("User Removed From List");
            } else {
              toast.error("Failed to Delete user!");
            }
          })
          .catch((error) => {
            // handle error response
          });
        console.log("Deleting...");
      }
    });
  };

 function formatDateOnly(dateString) {
    const date = new Date(dateString);
    return date.toISOString().split("T")[0];
  }


  return (
    <>
      <Bars
        height="80"
        width="80"
        color="#1b3bbb"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass="loading-spinner-overlay"
        visible={loading}
      />

        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <Card
            direction="column"
            w="100%"
            px="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
          >
            <Flex
              px="25px"
              justify="space-between"
              mb="20px"
              align="center"
              style={{ float: "right" }}
            >
              <Flex align="center">
                <SearchBar
                  searchValue={searchValue}
                  setSearchValue={setSearchValue}
                />
                {loader && <Spinner color="#1b3bbb" ml="2" />}{" "}
              </Flex>

              <Button size="sm" onClick={openAddModal} colorScheme="green">
                Add
              </Button>
            </Flex>

           

            <TableContainer>
              <Table mt="20px" variant="simple">
                <Thead>
                  <Tr>
                    <Th>Name</Th>                   
                    <Th>Email</Th>
                    <Th>Created At</Th>
                     <Th>Actions</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {usersData?.length === 0 ? (
                    <Tr>
                      <Td>No Data Available</Td>
                    </Tr>
                  ) : (
                    usersData?.data?.map((user) => (
                      <Tr>
                        <Td>{user?.name}</Td>
                        
                        <Td>{user?.email}</Td>
                        <Td>{formatDateOnly(user?.createdAt)}</Td>
                        <Td>
                        {role==="Admin" &&
                          <HStack spacing={2}>
                           <Button
                              size="sm"
                              onClick={() => openEditModal(user?._id)}
                              colorScheme="yellow"
                            >
                              Edit
                            </Button>
                               <>
                                <Button
                                  size="sm"
                                  colorScheme="red"
                                  onClick={() => handleDelete(user?._id)}
                                >
                                  Delete
                                </Button>
                               </>
                         </HStack>}
                        </Td>
                      </Tr>
                    ))
                  )}
                </Tbody>
              </Table>
            </TableContainer>
            <PaginatedLinks
              data={usersData}
              currentPage={currentPage}
              lastPage={lastPage}
              onPageChange={onPageChange}
            />

          

            <Modal isOpen={isAddModalOpen} onClose={closeAddModal} size="md">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Add User</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <form onSubmit={handleAddUser}>
                    <VStack spacing={4}>
                      <FormControl>
                        <FormLabel className="required"> Name</FormLabel>
                        <Input
                          type="text"
                          placeholder="Albert Frost"
                          name="name"
                          value={formData.name}
                          required
                          onChange={handleSetAddFields}
                          maxLength={50}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel className="required">Email</FormLabel>
                        <Input
                          type="email"
                          placeholder="your-mail@gmail.com"
                          name="email"
                          value={formData.email}
                          onChange={handleSetAddFields}
                          required
                        />
                      </FormControl>

                   

                    
                    
                      

                     
                      {/* Add more form fields as needed */}
                    </VStack>
                    <Button
                      type="submit"
                      colorScheme="blue"
                      mt={4}
                      style={{ float: "right" }}
                    >
                      Save
                    </Button>
                  </form>
                </ModalBody>
              </ModalContent>
            </Modal>

            <Modal isOpen={isEditModalOpen} onClose={closeEditModal} size="md">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Edit Employee</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <form onSubmit={handleEditSubmit}>
                    <VStack spacing={4}>
                      <FormControl>
                        <FormLabel> Name</FormLabel>
                        <Input
                          type="text"
                          placeholder="Enter name"
                          name="name"
                          value={editformData.name}
                          onChange={handleSetEditFields}
                          maxLength={50}
                          required
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel className="required">Email</FormLabel>
                        <Input
                          type="email"
                          placeholder="Enter Email"
                          name="email"
                          value={editformData.email}
                          onChange={handleSetEditFields}
                          required
                          disabled
                        />
                      </FormControl>

                     

                      
                    
                    </VStack>
                    <Button
                      type="submit"
                      colorScheme="blue"
                      mt={4}
                      style={{ float: "right" }}
                    >
                      Update
                    </Button>
                  </form>
                </ModalBody>
              </ModalContent>
            </Modal>
           
          </Card>
        </Box>
 

   
    </>
  );
}
